import './App.css';
import React from "react";
import Matrix from "./matrix/Matrix";

function App() {
  return (
    <div className="App">
      <Matrix fullscreen={true} speed={0.5}/>
    </div>
  );
}

export default App;
