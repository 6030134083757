import React, { createRef } from "react";
import { alphabet } from "./constants";

export default class Matrix extends React.Component {
  static defaultProps = {
    width: 640,
    height: 480,
    fullscreen: false,
    fontSize: 16,
    interval: 30,
    color: "#0F0",
    frequency: 0.005,
    speed: 1
  };

  constructor(props) {
    super(props);
    this.canvasRef = createRef()
    this.state = {
      canvas: null
    };


    this.draw = this.draw.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.setState({ canvas: this.canvasRef.current }, () => {
      let context = this.state.canvas.getContext("2d");

      const source = alphabet;

      let width = this.props.fullscreen ? window.innerWidth : this.props.width;
      let height = this.props.fullscreen ? window.innerHeight : this.props.height;

      const canvas = this.state.canvas;
      canvas.width = width;
      canvas.height = height;

      const columns = canvas.width / this.props.fontSize;
      const rainDrops = [];

      this.setState(
        { canvas, columns, context, source, rainDrops },
        () => {
          for (let i = 0; i < columns; i++) {
            rainDrops.push(0);
          }

          this.draw();
          console.log(this.props.interval / this.props.speed)
          let interval = setInterval(this.draw, this.props.interval / this.props.speed);

          this.setState({ interval });

          if (this.props.fullscreen)
            window.addEventListener("resize", this.updateDimensions);
        }
      );
    });
  }

  draw() {
    const context = this.state.context;
    const columns = this.state.canvas.width / this.props.fontSize;

    const rainDrops = this.state.rainDrops;

    context.fillStyle = "rgba(0,0,0,0.05)";
    context.fillRect(0, 0, this.state.canvas.width, this.state.canvas.height);

    context.fillStyle = this.props.color;
    context.font = this.props.fontSize + "px monospace";

    for (let i = 0; i < rainDrops.length; i++) {
      const text = this.state.source.charAt(Math.floor(Math.random() * this.state.source.length));

      context.fillText(text, i * this.props.fontSize, rainDrops[i] * this.props.fontSize);
      if (rainDrops[i] * this.props.fontSize > this.state.canvas.height && Math.random() > 0.975) {
        rainDrops[i] = 0;
      }
      rainDrops[i]++;
    }

    this.setState({ context, columns });
  }

  updateDimensions() {
    let canvas = this.state.canvas;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  }

  componentWillUnmount() {
    if (this.props.fullscreen)
      window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    let style = this.props.style ? this.props.style : {};
    return (
      <div
        style={{
          ...style,
          background: "#000000",
          width: this.props.fullscreen ? "100vw" : this.props.width + "px",
          height: this.props.fullscreen ? "100vh" : this.props.height + "px",
          overflow: "hidden",
          zIndex: this.props.zIndex
        }}
      >
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}
